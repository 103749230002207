@use '../config/'as *;

@each $event,
$event-key in $events {
    @include fav-event($event, $event-key);
}

.waf-profile {
    @extend %p-zero;
    @extend %m-zero;
    @extend %white-bg;
    .head {
        height: 9.5rem;
        @extend %flex-sb-c;
        @extend %c-blue-bg-10;
        @extend %m-x-half-neg;
        @extend %p-x-full;

    }
    .title{
        @extend %m-b-two-space;
    }

    .head-logo {
        width: 4rem;
        @extend %ratio-1-1;
    }

    .back-btn {
        .btn-text {
            @extend %font-14-pr;
            @extend %flex-n-c;
            @extend %c-white-10;

            &:before {
                @include icon('\e800');
                font-size: 2.4rem;//override the mixin value


            }
        }
    }

    .tabs {
        @extend %flex-column;
    }

    .tab {
        &-text {
            @extend %c-blue-6;
            @extend %font-14-pb;
        }

        &-name {
            border-bottom: 0;
            @extend %m-y-full;

            &.active {
                border-bottom: 0;
            }
            &.log-out{
                .btn-text{
                    @extend %c-orange-10;
                }
                &.active{
                    .btn-text{
                        font-family: $font-pb;
                    }
                }

            }
        }

        &-container-wrap {
            &.active {
                @extend %d-block;
            }
        }
    }

    .body {
        padding: var(--three-space) var(--half-space);
    }

    .btn-save {
        @extend %c-blue-bg-10;
        @extend %c-white-10;
        @extend %m-b-two-space;
        @extend %font-16-pr;
    }

    .btn-cancel {
        @extend %font-16-pb;
    }

    .form {
        &-body-wrapper {
            @extend %card;
            @extend %m-b-one-n-half;
            @include border-radius(var(--half-radius));
        }

        &-title {
            @extend %font-14-pb;
            @extend %c-blue-10;
            @extend %p-y-one-n-half;
            @extend %m-zero;    
        }

        &-group {
            position: relative;

            &:not(:last-child) {
                @extend %m-b-three-space;
            }
        }
        &-element{
            position: relative;
        }

        &-footer {
            @extend %flex-column;
            @extend %text-center;
        }


    }

    .profile-img {
        width: 11rem;
        margin: 0 auto var(--two-space);
        @extend %ratio-1-1;
        @extend %c-blue-bg-2;
        @extend %text-center;
        @extend %circle-radius;
    }

    .pic-description {
        @extend %font-12-pr;
        @extend %c-blue-10;

        .info {
            @extend %d-block;
            @extend %text-center;
            @extend %font-14-pr;

            &-headline {
                @extend %c-black-10;
                @extend %font-16-pb;
            }
        }
    }

    .radio-box-wrap {
        @extend %flex-sb-c;
        @extend %w-100;
    }

    .unit {
        @extend %c-blue-10;
    }

    .upload-image{
        position: relative;
        @extend %font-16-pb;
        @extend %c-blue-10;
        input{
            opacity: 0;
            @include position-combo(tl);
            @extend %w-100;
            @extend %h-100;


        }
    }
    p,.text{
        @extend %m-b-full;
        @extend %font-14-pr;
        @extend %c-blue-10;
    }


}

//user profile page style
.profile-page {
    @extend %c-blue-bg-10;
    @extend %c-white-10;
}

.waf-user-profile {
    margin-top: 2.7rem;
    @extend %p-x-half;
    @include border(1, c-white, 6, bottom);

    .masthead {
        &-wrap {
            @extend %flex;
            @extend %m-b-two-space;

            a {
                width: 2rem;
                height: 2rem;
                @extend %ratio-1-1;
                @extend %font-zero;

                &:before {
                    @include icon('\e851');
                    @include font(24);
                    @extend %c-white-10;
                }
            }
        }

        &-photo {
            width: 7rem;
            height: 7rem;
            flex-shrink: 0;

            position: relative;
            border: 0.2rem solid hsl(var(--hsl-c-white)/.3);
            background-image: linear-gradient(hsl(var(--hsl-c-blue-3)/1), var(--c-blue-3)),linear-gradient(260deg, hsl(var(--hsl-c-white)), var(--c-blue-3), var(--c-white), hsl(var(--hsl-c-blue-3)/0.3));
            background-origin: border-box;
            background-clip: padding-box,border-box;

            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %c-blue-3-bg-6;
            @extend %flex-c-c;
        }

        &-info {
            flex-wrap: wrap;
            width: calc(100% - 9rem);
            @extend %flex-column;
            @extend %c-white-10;
            @extend %m-l-two-space;

            .name {
                @extend %font-16-psb;
            }

        }
    }
    .user-name{
        width: 5rem;
        height: 5rem;
        background: url(/static-assets/images/cssimages/svg/profile.svg?v=#{$image-version}) hsl(var(--hsl-c-blue-3)/1) no-repeat;
        background-size: contain;
        @extend %font-22-pb;
        @extend %c-white-10;
        @extend %flex-c-c;
        @extend %circle-radius;
    }

    .origin {
        @extend %flex-n-c;

        .image {
            width: 2rem;
            height: 2rem;
            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %c-white-bg-10; //temp
        }

        .label {
            @extend %m-l-quarter;
        }
    }

    .profile {
        &-section-head {
            @extend %m-b-full;
            @extend %flex-sb-n;
            .title {
                @extend %c-white-10;
                @extend %font-14-pr;
                @extend %m-b-zero;
            }
        }

        &-section-body {
            .card {
                &-item {
                    @extend %p-full;
                    @extend %c-white-bg-1;
                    @extend %half-radius;
                    @extend %m-b-full;
                    @extend %flex-sb-c;

                    .title {
                        @extend %font-18-psb;
                        @extend %c-white-10;
                    }

                    .desc {
                        @extend %font-12-pr;
                    }

                    .number {
                        @extend %font-20-pr;
                    }
                }
            }
        }
    }

    .pass {
        &-list {
            @include card-count-new(1, var(--full-space));
            @extend %m-b-three-space;
        }
        &-item {
            @extend %p-full;
            @extend %half-radius;
            @extend %flex-column;
            @extend %c-white-bg-2;
            @extend %c-white-10;
            @extend %gap-full;
        }
        &-title {
            @extend %m-y-zero;
            @extend %font-18-pr;
        }
    }
    .meta {
        &-list {
            @extend %flex-column;
            @extend %gap-half;
        }
        &-value {
            @extend %c-pure-white-10;
            @extend %font-14-pb;
        }
        &-status .meta-value {
            @extend %c-orange-10;
        }
    }
    .action-wrap {
        margin-top: auto;
    }
    .btn-manage {
        @extend %c-orange-10;
        @extend %font-16-pb;
    }

    .profile-head {
        @extend %flex-sb-c;
        @extend %m-b-one-n-half;

        .btn-text {
            @extend %c-white-10;
        }

        // .btn-site {
        //     position: relative;
        //     @extend %p-r-full;
        //     @extend %p-y-half;
        //     @extend %p-l-two-space;
        //     @extend %c-white-bg-2;
        //     @extend %half-radius;
        //     &:before {
        //         left: 1rem;
        //         @extend %c-white-10;
        //         @extend %position-v-center;
        //         @include icon('\e819');
        //     }
        // }

        .title {
            @extend %font-14-pr;
            @extend %c-white-10;
        }
    }

    .fav-teams,
    .fav-athletes,
    .fav-tournament,
    .fav-tournament,
    .fav-styles {
        @extend %p-b-three-space;

        .card {
            &-list {
                flex-wrap: nowrap;
                overflow: auto;
                gap: var(--full-space);
                @extend %w-100;
                @extend %flex;
                @include custom-scroll;

            }
            &-wrapper {
                width: 100%;
                height: 100%;
            }
            &-item{
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                padding: 0;
            }
            &-thumbnail{
                height: 100%;
            }
        }
    }

    .fav-athletes,.fav-tournament,.fav-styles {
        .card-wrapper {
            position: relative;
            &::before {
                content: '';
                width: 2.2rem;
                height: 2.2rem;
                background-position: center;
                background: url('/static-assets/images/svg/star-icon.svg?v=#{$image-version}') no-repeat;
                z-index: var(--zindex1);
                @include position(var(--half-space),var(--half-space),null,null);
            }
        }
    }

    .fav-teams {
        .card {
            &-list {
                gap: calc(2*var(--full-space));
                @include custom-scroll;
            }
            &-item {
                background-color: transparent;
                overflow: visible;
                width: unset;
                @extend %flex-column;
                @extend %p-zero;
                &:first-child {
                    @extend %p-l-full;
                }
                &:last-child {
                    @extend %p-r-full;
                }
            }

            &-logo {
                width: 8.7rem;
                height: 8.7rem;
                @extend %ratio-1-1;
            }

            &-label {
                @extend %font-20-pr;
            }
            &-thumbnail{
                .image {
                    object-fit: cover;
                    @extend %w-100;
                    @extend %h-100;
                }
            }
        }
    }

    .fav-athletes {
        .card {
            &-list {
                flex-wrap: wrap;
                @include card-count(2.3, var(--full-space));

            }

            &-item {
                position: relative;
                word-break: break-all;
                @extend %c-light-grey-bg-10;
                // @include linear-gradient(180deg, hsl(var(--hsl-c-white)/0.8) 59.9%, hsl(var(--hsl-c-blue)/0.2) 115%);
                &::after {
                    content: "";
                    pointer-events: none;
                    z-index: var(--zindex0);
                    background: linear-gradient(180deg, hsl(var(--hsl-c-pure-black)/0) 53.90%, hsl(var(--hsl-c-blue)/0.70) 100%);
                    @extend %h-100;
                    @extend %w-100;
                    @extend %position-b-l;
                }
                .name {
                    @extend %font-14-pb;
                }
            }
            &-content {
                    z-index: var(--zindex1);
                    align-items: flex-start;
                    position: absolute;
                    bottom: var(--full-space);
                    left: var(--full-space);
            }

        }
    }

    .fav-tournament {
        .card {
            &-list {
                flex-wrap: wrap;
            }

            &-item {
                width: 14rem;
                height: 14rem;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                padding: var(--full-space);
            }

            &-thumbnail {
                width: 6rem;
                height: 6rem;
                z-index: var(--zindex1);
                @extend %m-b-full;
                @extend %ratio-1-1;
                @extend %text-center;
                @extend %m-auto;
            }

            &-title {
                @extend %font-14-pb;
                @extend %text-center;
            }

            &-content {
                position: relative;
                z-index: var(--zindex1);
            }

            &-wrapper {
                position: static;
            }
        }
    }

    .fav-styles {
        .card {
            &-list {
                @include card-count(2.3, var(--full-space));
            }

            &-item {
                position: relative;
                min-height: 14rem;
                @extend %ratio-1-1;

                &:after {
                    content: '';
                    opacity: .3;
                    z-index: var(--zindex-pattern);
                    @include border-radius(var(--half-radius));
                    @include linear-gradient(0deg, hsl(var(--hsl-c-blue-2)/.7) 0%, hsl(var(--hsl-c-blue-2)/.7) 100%);
                    @include position-combo(inset);
                }
                &:hover {
                    &::after {
                        opacity: .5;
                    }
                }
            }

            &-title {
                @extend %text-center;
                @extend %font-14-pb;
            }
                    &-content{
                        @include position-combo(center);
                    }
        }
    }
    .complete-your-profile{
        padding: var(--two-space) var(--one-n-half-space);
        @extend %card;
        @extend %text-center;
        @extend %m-b-full;
        @include linear-gradient(104.13deg, hsl(var(--hsl-c-white) / 0.24) -16.79%, hsl(var(--hsl-c-white) /0.06) 38.23%, hsl(var(--hsl-c-white) /0.06) 67.48%, hsl(var(--hsl-c-white) /0.24) 116.93%);
        .icon{
            position: relative;
            width: 10.5rem;
            background: url("/static-assets/images/cssimages/star.png?v=#{$image-version}") no-repeat;
            background-size: cover;
            @extend %ratio-1-1;
            @extend %m-auto;
            @extend %d-block;
        }
        .title{
            @extend %c-white-10;
            @extend %font-14-pb;
            @extend %m-t-one-n-half;
        }
        .text{
            @extend %m-y-one-n-half;
            @extend %font-14-pr;
        }
        .btn-outline{
            @extend %font-14-pr;
            @extend %w-100;
        }
    }

    .team-group {
        .card-item {
            &.add {
                @extend %bg-transparent;
                .card-label {
                    @extend %d-block;
                    @extend %c-white-6;
                }
            }
        }
    }
    .profile-section-body {
        .card-item {
            &.add {
                height: 100%;
                @extend %flex-c-c;
                @extend %c-white-bg-1;
                &::after {
                    content: unset;
                }
                .btn-site {
                    width: 8.7rem;
                    height: 8.7rem;
                    @extend %relative;
                    @extend %c-blue-2-bg-10;
                    @extend %circle-radius;
                    &:before {
                        content: '\e819';
                        @include font(25,25,$font-icon);
                        @extend %c-white-10;
                        @extend %position-center;
                    }
                    .btn-text {
                        @extend %font-zero;
                    }
                }
                .card-label {
                    @extend %d-none;
                }
            }
        }
    }
    .athletes-group {
        .card-item {
            &.add {
                height: 15.7rem;
            }
        }
    }
}

.subscribtion {
    .profile-section-body {
        .card-list {
            @include custom-scroll;
            @extend %flex;
            overflow: auto;
            column-gap: var(--full-space);
        }
        .card-item {
            flex-shrink: 0;
            @extend %text-center;
            @extend %w-100;
        }
    }
}

@media screen and (min-width:$tablet-min-width) {
    .waf-profile {
        .head {
            background: transparent;

            &-logo {
                display: none;
            }
        }
        .title{
            margin-bottom: 6rem;
        }

        .back-btn {
            .btn-text {
                @include color(c-blue);
                @include font(16);

                &::before {
                    @include font(25);
                }
            }
        }

        .tab {
            &-section {
                @include flex-config(flex);
            }

            &-container {
                width: calc(100% - 18rem);
            }

        }

        .tabs {
            width: 18rem;
        }

        .form {
            &-body-wrapper {
                padding: var(--two-space);
                @include flex-config(flex);
            }

            &-title {
                padding: 0;
                width: 16rem;
            }

            &-container {
                width: calc(100% - 16rem);
            }

            &-footer {
                width: 30rem;
                margin: 0 0 var(--two-space) auto;
                @include flex-config(null, row-reverse, space-between, center);
            }

        }

        .btn-save {
            margin: 0;
        }

        .profile-img {
            margin: 0;
        }

        .pic-description {
            margin-left: var(--three-space);

            .info {
                text-align: left;
            }
        }
    }

    //user profile page css
    .waf-user-profile {
        margin-top: 6.7rem;
        .profile-head {
            .title {
                @include font(18);
            }
        }
        .profile-section-head {
            .title, .view-more {
                font-size: 1.8rem;
            }
        }

        
        .user-name{
            width: 10rem;
            height: 10rem;
            font-size: 3.6rem;
        }

        .origin {
            .image {
                width: 4rem;
                height: 4rem;
            }
        }


        .fav-athletes,
        .fav-tournament,
        .fav-tournament,
        .fav-styles {
            .card {
                &-list {
                    &>* {
                        width: 19.4rem;
                        height: 19.4rem;
                        aspect-ratio: 1/1;
                    }
                }
            }
        }

        .fav-athletes {
            .card {
                &-item {
                    .name {
                        @include font(16);
                    }
                }

                &-name {
                    display: contents;
                }
            }
        }

        .fav-tournament {
            .card {
                &-thumbnail {
                    width: 9rem;
                    height: 9rem;
                }

                &-item {
                    padding: var(--one-n-half-space);
                }

            }
        }
        .complete-your-profile{
            padding-block: calc(var(--three-space) + var(--half-space));
            .title,.text{
                max-width: 40rem;
                text-align: center;
                margin: auto;
                @include font(16);
            }
            .btn{
                max-width: 40rem;
            }
            .text{
                margin-block: var(--one-n-half-space);
            }
        }

        .athletes-group {
            .card-item {
                &.add {
                    height: 19.4rem;
                }
            }
        }
    }

    .subscribtion {
        .profile-section-body {
            .card-list {
                flex-wrap: wrap;
                @include card-count(3, var(--full-space));
            }
        }
    }
}

@media (min-width:$desktop-min-width) {
    .waf-profile{
        .tabs{
            width: 23rem;
        }
        .tab{
            &-container{
                width: calc(100% - 23rem);
            }
        }
        .form{
            &-title{
                width: 25rem;
            }
            &-container{
                width: calc(100% - 25rem);
            }
        }

    }
    .waf-user-profile{
        .masthead {
            &-photo {
                width: 14rem;
                height: 14rem;
            }

            &-info {
                margin-left: 5.2rem;
                .name {
                    @include font(32);
                }
            }
            &-wrap{
                margin-inline: 2rem 9rem;
            }
        }
        .profile-body{
            width: calc(100% - 30rem);
            margin-inline: auto 9rem;
        }
    }
    
}
@media screen and (min-width: $large-desktop-min-width) {
    .waf-user-profile {
        .pass {
            &-list {
                margin-bottom: calc(var(--full-space) * 4);
                overflow-x: unset;
                flex-wrap: wrap;
            }
            &-item {
                align-items: center;
                flex-direction: row;
                gap: 5%;
            }
        }
        .meta {
            &-list {
                flex-grow: 1;
                flex-direction: row;
                gap: 7%;
            }
            &-item {
                @include flex-config(flex, column);
            }
        }
        .action-wrap {
            margin-top: unset;
            margin-left: auto;
        }
    }
}